(function ImgViewer() {
  var initViewerEvents = function() {
    $(document).on('init', '.img-viewer', function() {
      var $this = $(this);
      if ($this.hasClass('initialized')) return;
      if ($this.data('type') === 'slider') initSlider($this);
      if ($this.data('type') === 'carousel') initCarousel($this);
      $(this).addClass('initialized');
    });
    $(document).on('visualize', '.img-viewer .item', function () {
      visualizeItem($(this).closest('.img-viewer'), $(this));
    });
  };
  var initSlider = function($viewer) {
    initItemsEvents($viewer);
    initItems($viewer);
    loadVisibleItems($viewer)
    initSliderButtonsEvents($viewer);
    initSliderButtons($viewer);
  };
  var initCarousel = function($viewer) {
    initItemsEvents($viewer);
    initItems($viewer);
    loadVisibleItems($viewer);
    initCarouselButtonsEvents($viewer);
    initCarouselButtons($viewer);
  };
  var visualizeItem = function($viewer, $item) {
    $viewer.css('height', $viewer.find('.item.visible:first').outerHeight());
    $viewer.find('.item').removeClass('visible');
    $item.addClass('visible');
    var columns = $viewer.css('grid-template-columns').split(' ').length;
    for (var i = 1; i < columns; i++) {
      var $next_item = $viewer.find('.item.visible:last').next();
      if ($next_item.hasClass('item')) {
        $next_item.addClass('visible');
        continue;
      }
      var $prev_item = $viewer.find('.item.visible:first').prev();
      if ($prev_item.hasClass('item')) {
        $prev_item.addClass('visible');
        continue
      }
      break;
    }
    $viewer.trigger('change');
    if ($viewer.data('type') === 'slider') initSliderButtons($viewer);
    loadVisibleItems($viewer);
  };
  var initSliderButtons = function($viewer) {
    $viewer.find('.button').removeClass('visible');
    if ($viewer.find('.item.visible:first').prev().hasClass('item')) {
      $viewer.find('.button.prev').addClass('visible');
    }
    if ($viewer.find('.item.visible:last').next().hasClass('item')) {
      $viewer.find('.button.next').addClass('visible');
    }
    if (!$viewer.find('.button').hasClass('visible')) $viewer.addClass('no-buttons');
  };
  var initSliderButtonsEvents = function($viewer) {
    $viewer.find('.button.next').click(function() {
      var $hide_item = $viewer.find('.item.visible:first');
      var $show_item = $viewer.find('.item.visible:last').next();
      $viewer.css('height', $hide_item.outerHeight());
      $hide_item.removeClass('visible');
      $show_item.addClass('visible');
      $viewer.trigger('change');
      initSliderButtons($viewer);
      loadVisibleItems($viewer);
    });
    $viewer.find('.button.prev').click(function() {
      var $hide_item = $viewer.find('.item.visible:last');
      var $show_item = $viewer.find('.item.visible:first').prev();
      $viewer.css('height', $hide_item.outerHeight());
      $hide_item.removeClass('visible');
      $show_item.addClass('visible');
      $viewer.trigger('change');
      initSliderButtons($viewer);
      loadVisibleItems($viewer);
    });
  };
  var initCarouselButtons = function($viewer) {
    var columns = $viewer.css('grid-template-columns').split(' ').length;
    if ($viewer.find('.item').length > columns) {
      $viewer.find('.button').addClass('visible');
    }
  };
  var initCarouselButtonsEvents = function($viewer) {
    $viewer.find('.button.next').click(function() {
      var $hide_item = $viewer.find('.item.visible:first');
      var $show_item = $viewer.find('.item.visible:last').next();
      if (!$show_item.hasClass('item')) {
        $show_item = $viewer.find('.item:first');
        $viewer.find('.item:last').after($show_item);
      }
      $viewer.css('height', $hide_item.outerHeight());
      $hide_item.removeClass('visible');
      $show_item.addClass('visible');
      $viewer.trigger('change');
      loadVisibleItems($viewer);
    });
    $viewer.find('.button.prev').click(function() {
      var $hide_item = $viewer.find('.item.visible:last');
      var $show_item = $viewer.find('.item.visible:first').prev();
      if (!$show_item.hasClass('item')) {
        $show_item = $viewer.find('.item:last');
        $viewer.find('.item:first').before($show_item);
      }
      $viewer.css('height', $hide_item.outerHeight());
      $hide_item.removeClass('visible');
      $show_item.addClass('visible');
      $viewer.trigger('change');
      loadVisibleItems($viewer);
    });
  };
  var initItems = function($viewer) {
    var offset = $viewer.data('offset') || 0;
    var columns = $viewer.css('grid-template-columns').split(' ').length;
    if (columns == 1) columns = $viewer.css('grid-template-rows').split(' ').length;
    var items = $viewer.find('.item').length;
    offset = offset > items - columns ? items - columns : offset;
    offset = offset < 0 ? 0 : offset;
    $viewer.find('.item').slice(offset, columns + offset).addClass('visible');
  };
  var initItemsEvents = function($viewer) {
    var setZoomedImgPosition = function($item, pageX, pageY) {
      var $img = $item.find('img.zoomed');
      var item_width = $item.width();
      var item_height = $item.height();
      var img_width = $img.width();
      var img_height = $img.height();
      var x_shift = (pageX - $item.offset().left) / item_width;
      var y_shift = (pageY - $item.offset().top) / item_height;
      $img.css('left', (img_width - item_width) * -x_shift);
      $img.css('top', (img_height - item_height) * -y_shift);
    }
    $viewer.find('.item img:not([src])').on('load', function() {
      $(this).closest('.item').addClass('loaded');
      $viewer.css('height', 'auto');
    });
    $viewer.find('.item img:not([src])').on('error', function() {
      $(this).closest('.item').addClass('loaded');
    });
    $viewer.find('.item.zoom').click(function(e) {
      var $zoomed = $(this).find('img.zoomed');
      if ($zoomed.length) {
        $zoomed.remove();
      } else {
        $(this).find('img').clone().removeAttr('title').addClass('zoomed').appendTo($(this));
        setZoomedImgPosition($(this), e.pageX, e.pageY);
      }
    });
    $viewer.find('.item.zoom').mousemove(function(e) {
      setZoomedImgPosition($(this), e.pageX, e.pageY);
    });
    $viewer.find('.item.zoom').on('touchmove', function(e) {
      if (!$(e.target).hasClass('zoomed')) return;
      e.preventDefault();
      var pageX = e.originalEvent.touches[0].pageX;
      var pageY = e.originalEvent.touches[0].pageY;
      var offset = $(this).offset();
      if (pageY < offset.top || pageY > offset.top + $(this).height()) return;
      if (pageX < offset.left || pageX > offset.left + $(this).width()) return;
      setZoomedImgPosition($(this), pageX, pageY);
    });
  };
  var loadVisibleItems = function($viewer) {
    $viewer.find('.item.visible').each(function() {
      $img = $(this).find('img');
      if ($img.attr('src')) {
        $(this).addClass('loaded');
        $viewer.css('height', 'auto');
      } else {
        $img.attr('src', $img.data('src'));
      }
    });
  };
  initViewerEvents();
})();
